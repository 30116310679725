<template>

  <v-card>
    <v-card-title>
      <v-container fluid class="pa-0">
        <v-row>
          <v-col>
            Hourly summed by day for last 7 days
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>

    <v-simple-table>
      <thead>
        <tr>
          <th>Recipient(s)</th>
          <th>Condition</th>
          <th># today</th>
          <th># yesterday</th>
          <th># t -2</th>
          <th># t -3</th>
          <th># t -4</th>
          <th># t -5</th>
          <th># t -6</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.emails_csv+item.condition">
          <td>{{item.emails_csv}}</td>
          <td>{{item.condition}}</td>
          <td>{{item.today}}</td>
          <td>{{item.yesterday}}</td>
          <td>{{item.t_2}}</td>
          <td>{{item.t_3}}</td>
          <td>{{item.t_4}}</td>
          <td>{{item.t_5}}</td>
          <td>{{item.t_6}}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>

</template>

<script>
export default {
  name: 'NotificationsStatsHourly',

  data () {
    return {
      items: [],
      showErrorAlert: false,
      errorMsg: '',
      endpoint: '/a/core/notifications-by-date-hourly?xper_page=5000',
    }
  },

  methods: {
    fetchData: function () {
      this.$http.get(this.endpoint).then(resp => {
        if (resp.data.data) {
          this.items = resp.data.data
        } else {
          this.items = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: this.endpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
    },
  },

  created: function () {
    this.fetchData()
  }
}
</script>
